var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"siteKeyAct_wrapper  pb-10 pb-12"},[_c('div',{staticClass:"filterWrapper",attrs:{"xv-if":"!currentCountryNotEnglish"}},[_c('div',{staticClass:"filterBorder"},[_c('v-text-field',{attrs:{"dark":_vm.appSettings.listFilterTabDark === true,"background-color":"filterButtonColor","solo":"","label":"List Filter","prepend-inner-icon":"mdi-text-search-variant","clearable":""},model:{value:(_vm.wordFilter),callback:function ($$v) {_vm.wordFilter=$$v},expression:"wordFilter"}})],1)]),_c('div',{staticClass:"filterWrapperSpacer",staticStyle:{"height":"50px"}}),_c('div',{staticClass:"siteListWrapper"},[_c('TrailItem'),_c('v-list',{staticClass:"pt-0",class:[_vm.wordFilterClean ? 'filterRunning' : '']},[_c('v-list-item-group',{attrs:{"color":"info"},model:{value:(_vm.sidebarSiteActPlus),callback:function ($$v) {_vm.sidebarSiteActPlus=$$v},expression:"sidebarSiteActPlus"}},_vm._l((_vm.menuListItems),function(section){return _c('v-list-group',{directives:[{name:"show",rawName:"v-show",value:(
            !section.hideSection &&
              (!_vm.wordFilterClean || _vm.wordFilterCatList[section.key])
          ),expression:"\n            !section.hideSection &&\n              (!wordFilterClean || wordFilterCatList[section.key])\n          "}],key:section.key,class:[
            'catKeyAct_' + section.key,
            _vm.sectionClasses[section.key]
          ],attrs:{"eager":"","xv-model":"section.active","no-action":"","aria-label":("" + (section.title))},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(section.avatar)?_c('v-list-item-avatar',{staticClass:"sectionAvatar",attrs:{"size":"46"}},[_c('img',{attrs:{"src":section.avatar,"aria-label":("" + (section.title))}})]):_c('v-list-item-avatar',{staticClass:"sectionAvatar",attrs:{"size":"46","color":"questGold"}}),_c('v-list-item-content',{attrs:{"eager":""}},[_c('v-list-item-title',{staticClass:"text-header text-wrap font-weight-medium font-weight-normal secondary--text ",attrs:{"aria-label":("" + (section.title))}},[_vm._v(" "+_vm._s(section.title)+" "),(section.setup && section.setup.firstNationsName)?_c('font',{staticClass:"notranslate",attrs:{"translate":"no","title":("First Nations place names: " + (section.setup.firstNationsName))}},[_vm._v(" / "+_vm._s(section.setup.firstNationsName)+" ")]):_vm._e()],1)],1)]},proxy:true}],null,true)},[_vm._l((section.items),function(site){return [_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
                !_vm.wordFilterClean ||
                  (_vm.wordFilterClean &&
                    ((site.title &&
                      site.title.toLowerCase() &&
                      site.title.toLowerCase().includes(_vm.wordFilterClean)) ||
                      (_vm.currentCountryNotEnglish &&
                        _vm.translatedList[_vm.translationLanguageCode] &&
                        _vm.translatedList[_vm.translationLanguageCode][site.title] &&
                        _vm.translatedList[_vm.translationLanguageCode][site.title]
                          .toLowerCase()
                          .includes(_vm.wordFilterClean))))
              ),expression:"\n                !wordFilterClean ||\n                  (wordFilterClean &&\n                    ((site.title &&\n                      site.title.toLowerCase() &&\n                      site.title.toLowerCase().includes(wordFilterClean)) ||\n                      (currentCountryNotEnglish &&\n                        translatedList[translationLanguageCode] &&\n                        translatedList[translationLanguageCode][site.title] &&\n                        translatedList[translationLanguageCode][site.title]\n                          .toLowerCase()\n                          .includes(wordFilterClean))))\n              "}],key:site.key,staticClass:"actListItem",class:[
                'listItemAct',
                'ml-0',
                'siteKeyAct_' + site.properties.index,
                _vm.sidebarSite === site.properties.index ? 'currentlyOpen' : ''
              ],attrs:{"eager":"","color":"info","value":1000000 + site.properties.index,"aria-label":("" + (site.title))},on:{"click":function($event){$event.preventDefault();return _vm.focusOnSite(site.properties.index)},"mouseenter":function($event){return _vm.hoverOnListItem(site.properties.index)},"dblclick":function($event){return _vm.loadMoreInfo(site.properties.index)}}},[_c('v-list-item-avatar',{staticClass:"itemAvatar",staticStyle:{"margin-right":"18px","margin-left":"1px"},attrs:{"tile":"","size":"28"}},[_c('img',{attrs:{"src":site.properties.list_image_obj.marker,"aria-label":("" + (section.title))}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-header text-wrap  secondary--text"},[_c('engAndTrans',{attrs:{"value":site.title}})],1)],1)],1)]})],2)}),1)],1),(!_vm.sites.length || !_vm.trailDestinationSlugs.length)?_c('center',[_c('br'),_c('div',{staticClass:"ml-4 heading right"},[_vm._v("... no items or no destinations")])]):_vm._e(),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }