const trail001_coastToGold = require("./trailGeoms/trail001_coastToGold.json");
const trail002_capitalToCoast = require("./trailGeoms/trail002_capitalToCoast.json");
const trail003_throughTheHeartOfAustralia = require("./trailGeoms/trail003_throughTheHeartOfAustralia.json");

const trail004_cityToSerenity = require("./trailGeoms/trail004_cityToSerenity.json");
const trail005_coastalCrawl = require("./trailGeoms/trail005_coastalCrawl.json");
const trail006_followTheVines = require("./trailGeoms/trail006_followTheVines.json");
const trail007_GoldCoasttoGardenCity = require("./trailGeoms/trail007_GoldCoasttoGardenCity.json");

let trailGeoms = [
  {
    slug: "goldfields-to-great-ocean-road",
    title: "Goldfields to Great Ocean Road",
    geoJson: trail001_coastToGold
  },
  {
    slug: "capital-to-coast",
    title: "Capital to Coast",
    geoJson: trail002_capitalToCoast
  },
  {
    slug: "through-the-heart-of-australia",
    title: "Through the Heart of Australia",
    geoJson: trail003_throughTheHeartOfAustralia
  },
  {
    slug: "city-to-serenity",
    title: "City to Serenity",
    geoJson: trail004_cityToSerenity
  },
  {
    slug: "coastal-crawl",
    title: "Coastal Crawl",
    geoJson: trail005_coastalCrawl
  },
  {
    slug: "follow-the-vines",
    title: "Follow the Vines",
    geoJson: trail006_followTheVines
  },
  {
    slug: "gold-coast-to-garden-city",
    title: "Gold Coast to Garden City",
    geoJson: trail007_GoldCoasttoGardenCity
  }
];

export default new (class {
  async getTrailGeom(formatTrailLookup) {
    let geoJsonFeatureCollection = {
      type: "FeatureCollection",
      features: []
    };

    trailGeoms.forEach(row => {
      let { geoJson, slug, title } = row;
      try {
        let [feature] = geoJson.features;

        if (feature && feature.properties) {
          feature.properties = { slug, title, active: false };
        }

        if (formatTrailLookup[slug]) {
          geoJsonFeatureCollection.features.push(feature);
        } else {
          console.error("missing geom for ", slug);
        }
      } catch (error) {
        //console.error(error);
      }
    });

    return geoJsonFeatureCollection;
  }
})();
