const siteSwitcherService = require("./../../service/siteSwitcherService.js");

const siteGlobal = siteSwitcherService.getSiteSetup().global;

const getters = {};
const mutations = {
  SET_maptivateSetupObject(state, newValue) {
    state.maptivateSetupObject = newValue;
  }
};

const actions = {
  async global_updateMaptivateSetupObject({ commit }, wpData) {
    commit("SET_maptivateSetupObject", wpData.maptivateSetupObject);
  }
};
const state = {
  siteId: siteSwitcherService.getId(),
  siteObject: siteSwitcherService.getSiteObj(),
  siteSetup: siteSwitcherService.getSiteSetup(),
  maptivateSetupObject: {},
  appSettings: {
    //wpEditSite:"https://cms01.maptivateapp.com.au/sandbox01/wp-admin/post.php?post=",

    onMoreInfoLoadOnMobileExpanText: true,

    pointsHaveDirections: true,
    markerIsPointer: false,
    showSiteNumbers: false,
    showSiteNumbers_Labels: true,
    showSiteNumbers_ClusterLabels: false,
    showQrCode: false,
    minZoom: { main: 1, secondary: 1, points: 1 },

    //nonClusteredDataSouces: [],
    //clusteredDataSouces: ["places", "signage"],

    useMapClustering: true,

    layersUnder: {
      art: "settlement-major-label", //"melbzoo-labels (1)",
      places: null, //"melbzoo-labels (1)",
      signage: null, //"melbzoo-labels (1)",
      lines: false,
      fill: false,
      arrows: false
    },
    defaultTooltipLabelField: "mv_tooltip",

    markerImageAssetOrderPriority: {
      //list by section slug name,
      artwork_section: {
        catTaxonomy: "artwork_section",
        name: "artwork",
        behaviour: "normal", //normal, cluster,
        skip: [],
        priority: [
          "artwork_zoom1",
          "animals-hero",
          "buildings-hero",
          "north-arrow"
        ],
        delay: []
      },
      signage_section: {
        catTaxonomy: "signage_section",
        name: "signage",
        behaviour: "normal", //normal, cluster,
        skip: [""],
        priority: [""],
        delay: ["sensory"]
      },
      markersection: {
        catTaxonomy: "markersection",
        name: "marker",
        behaviour: "cluster",
        load: []
      }
    },

    siteListSettings: {
      itemUseCircle: true,
      sectionUseCircle: true
    },

    allowSkipWelcomeDialog: false,

    autoOpenMoreInfo: false,
    drawWidth: 450,
    mobDrawWidth: 300,

    popupButtonWidth: "269px",
    drawButtonWidth: "435px",

    mediaButtonColor: "mediaButtonColor", //a6caa6
    filterLegendButtonColor: "filterLegendButtonColor", //a6caa6

    audioPlayColor: "white",
    headingTextColor: "font-weight-regular text--darken-1",

    mainTabDark: false,
    listFilterTabDark: false,

    langButtonTextDark: true,

    styles: {
      markerColours: [],
      //oldmarkerText: "#f6e9d8",
      markerTextLabel: "black",
      markerText: "black",
      markerTextBg: "black"
    },

    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-alarm-multiple ",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },
    ...siteGlobal.appSettings
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
