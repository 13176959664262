<template>
  <div
    style=" 
    top: 60px;

    position: absolute;

    left: 50%;
    transform: translateX(-50%);

    color: black !important;"
  >
    <div
      class="timeline-container"
      v-show="!$vuetify.breakpoint.xsOnly && includedDestinationsArray.length"
    >
      <div class="timeline">
        <!-- Timeline Item -->
        <div
          v-for="(section, index) in includedDestinationsArray"
          :key="index"
          class="timeline-item"
          :class="{ selected: section.destinaton.slug === activeSection }"
        >
          <!-- Timeline Marker (dot) -->
          <div class="timeline-marker">
            <span class="marker-dot" @click="openPoint(section)"></span>
          </div>

          <!-- Title under the marker -->
          <div class="timeline-title">
            <v-btn
              @click="openPoint(section)"
              class="timeline-title-btn pa-0 ma-0 px-1 notranslate"
              small
              elevation="0"
              :color="
                section.destinaton.slug === activeSection
                  ? 'questGold'
                  : 'questStone'
              "
            >
              <font
                :class="
                  section.destinaton.slug === activeSection
                    ? 'white--text'
                    : 'white--text'
                "
                >{{ section.destinaton.title }}</font
              >
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div v-show="$vuetify.breakpoint.xsOnly">
      <div class="d-flex flex-row">
        <v-btn
          title="Show Legend"
          z-depth="0"
          depressed
          small
          class="ml-2 pa-0 px-2 trailSelectOptionsIcons"
          :color="'filterLegendButtonColor'"
          @click="
            openTrailSelector();
            showMobileList = false;
          "
        >
          <div class="custiconsWrapper ">
            <v-icon
              color="white"
              style="font-size: 15px;z-index: 5;"
              class="custicons"
              >mdi-chart-timeline-variant</v-icon
            >

            <v-icon
              style="font-size: 25px;z-index: 2;color: #e6e6e6 !important;"
              class="custicons"
              >mdi-map</v-icon
            >
          </div>

          <font class="text--white  white--text ">
            <font class="extraRoadTripBits">Road</font> Trips</font
          >
        </v-btn>

        <div style="    z-index: 1;" v-show="includedDestinationsArray.length">
          <v-btn
            title="Show Legend"
            z-depth="0"
            depressed
            small
            class="ml-2 pa-0 px-2"
            :color="'filterLegendButtonColor'"
            @click="showMobileList = !showMobileList"
          >
            <v-icon color="white">mdi-map-marker-radius </v-icon>
            <font class="text--white  white--text ">
              Destinations
              <v-icon>
                mdi-menu-down
              </v-icon>
            </font>
          </v-btn>

          <div v-show="showMobileList" style="position:absolute; top:0">
            <v-card>
              <v-toolbar color="" dense height="35" text elevation="0">
                <v-toolbar-title elevation="0">Destinations </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="showMobileList = false"
                  title="Close Destinations"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <center>
                <div
                  v-for="(section, index) in includedDestinationsArray"
                  :key="index"
                  :class="{
                    selected: section.destinaton.slug === activeSection
                  }"
                >
                  <v-btn
                    @click="
                      showMobileList = false;
                      openPoint(section);
                    "
                    :color="
                      section.destinaton.slug === activeSection
                        ? 'questGold'
                        : ''
                    "
                    class="pa-0 ma-0 mt-1"
                    small
                    text
                    width="100%"
                  >
                    {{ section.destinaton.title }}
                  </v-btn>
                </div>
              </center>
            </v-card>
          </div>
        </div>
        <v-btn
          v-show="includedDestinationsArray && includedDestinationsArray.length"
          @click="
            focusOnTrail();
            showMobileList = false;
          "
          title="Focus on Entire Trail"
          dark
          depressed
          small
          class="ml-2 "
          :color="'filterLegendButtonColor'"
        >
          <img
            :src="`./assets/icons/focusOnTrail__White.png`"
            style="height: 22px;width: 22px;"
          />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "mvTimeLine",

  data() {
    return {
      showMobileList: false
    };
  },

  methods: {
    focusOnTrail() {
      eventBus.$emit("nav_map_focus_on_trail");
    },
    openTrailSelector() {
      eventBus.$emit("openTrailDialog");
    },
    openPoint(section) {
      window.mvAnalyticEvent(
        "timeline",
        "selectDest",
        section?.point?.destSlug,
        false
      );

      if (section?.point?.Point_Name) {
        eventBus.$emit(
          "nav_map_flyToOpenPopUpByName",
          section?.point?.Point_Name
        );
      }
    }
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      //trailSelectOptions: state => state.filters.trailSelectOptions,
      formatTrailLookup: state => state.filters.formatTrailLookup,
      trailSelect: state => state.filters.trailSelect,

      screenQuerySections: state => state.navigation.screenQuerySections,
      sidebarSite: state => state.navigation.sidebarSite,

      markerList: state => state.markers.markerList
    }),

    trail() {
      return this.formatTrailLookup[this.trailSelect];
    },
    includedDestinations() {
      return this.trail?.includedDestinations;
    },

    includedDestinationsArray() {
      try {
        return Object.values(this.trail?.includedDestinations);
      } catch (error) {
        return [];
      }
    },
    sites() {
      return this.markerList.data.features;
    },
    properties() {
      try {
        if (
          this.sites &&
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          //          console.log(this.sites[this.sidebarSite].properties);
          return this.sites[this.sidebarSite].properties;
        } else {
          //return null;
          throw { error: "error" };
        }
      } catch (error) {
        return null;
      }
    },

    currentSectionSlug() {
      return this?.properties?.section_slug;
    },

    activeSection() {
      if (this.currentSectionSlug) {
        return this.currentSectionSlug;
      } else if (this.screenQuerySections.length === 1) {
        return this.screenQuerySections[0];
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped>
/* Basic container to hold the timeline */
.timeline-container {
  width: 100%;
  overflow-x: auto; /* Allows horizontal scrolling if needed */
  overflow-x: visible;
  padding-top: 10px;
}

/* Timeline as a horizontal flex container */
.timeline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

/* Individual timeline item */
.timeline-item {
  text-align: center;
  flex: 0 0 auto;
  width: 100px; /* Fixed width for each timeline item */
  position: relative;
  margin: 0 10px; /* Spacing between timeline items */
}

/* Timeline marker (can be a dot or an icon) */
.timeline-marker {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  background-color: #3498db;
  background-color: #bf6440;
  background-color: #51534a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Connecting line between markers */
.timeline-marker::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%; /* Start from the right edge of the marker */
  width: 100px; /* Length of the connecting line */
  height: 4px;
  background-color: #3498db; /* Same color as the marker */
  background-color: #bf6440; /* Same color as the marker */
  background-color: #51534a; /* Same color as the marker */
  z-index: -1; /* Place the line behind the marker */
  margin-top: -2px;
}

.timeline-item:last-child .timeline-marker::before {
  display: none; /* Remove the line after the last item */
}

/* The dot inside the marker */
.marker-dot {
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
}

.marker-dot:hover {
  cursor: pointer;
}

/* Timeline title under the marker */
.timeline-title {
  margin-top: 3px;
  font-weight: bold;
}
.timeline-title button.v-btn.timeline-title-btn {
  height: 22px;
}

.timeline-item.selected .timeline-marker {
  background-color: #9b8c5e; /* Same color as the marker */
}

.timeline-item.selected .marker-dot {
  background-color: white; /* Same color as the marker */

  background-color: black; /* Same color as the marker */
  background-color: #51534a; /* Same color as the marker */
}

@media only screen and (max-width: 333px) {
  .extraRoadTripBits {
    display: none;
  }
}
</style>
