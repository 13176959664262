<template>
  <div class="text-center ">
    <v-dialog
      content-class="trailSelectorDialogWrapper"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="openTrailSelectorDialog"
      :width="$vuetify.breakpoint.mdAndDown ? 700 : 1200"
      transition="dialog-top-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="linkButton" v-if="false">
          Trail
        </v-btn>
      </template>

      <v-card style="overflow:hidden">
        <v-toolbar
          class="trailSelectorToolBar"
          dark
          color="dialogToobarColor"
          elevation="1"
          :xheight="$vuetify.breakpoint.smAndDown ? 42 : 54"
        >
          <v-spacer v-show="!$vuetify.breakpoint.smAndDown"></v-spacer>
          <v-toolbar-title>
            <font v-show="!$vuetify.breakpoint.smAndDown"
              >{{ siteObject.title }} - Select A Road Trip</font
            >

            <div v-show="$vuetify.breakpoint.smAndDown">
              <b>{{ siteObject.title }}</b>
            </div>
            <div v-show="$vuetify.breakpoint.smAndDown">Select A Road Trip</div>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn dark icon @click="openTrailSelectorDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <center>
          <v-container
            class="d-flex flex-row flex-wrap filterSection justify-center pb-1 pt-2"
          >
            <v-btn-toggle
              tile
              v-model="selectedStates"
              multiple
              class="stateSelector d-flex flex-row flex-wrap"
            >
              <template
                v-for="state in allTaxonomiesLookup.all['state'].keysAll"
              >
                <v-btn
                  tile
                  :key="state"
                  :value="state"
                  class="mx-0 px-0"
                  small
                  @click="$event.target.blur()"
                  :color="
                    selectedStates.includes(state) ? 'grey' : 'grey lighten-2'
                  "
                >
                  <span class="notranslate" translate="no">{{ state }} </span>
                </v-btn></template
              >
            </v-btn-toggle>
            <v-text-field
              v-show="!currentCountryNotEnglish"
              x-small
              class="ma-0 pa-0 ml-2"
              single-line
              v-model="keywordFilter"
              label="Keyword Filter"
              prepend-icon="mdi-magnify"
              width="150px"
            ></v-text-field>

            <v-btn
              title="Clear Filters"
              small
              icon
              color="questStone"
              @click="resetFilters()"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-container>
        </center>
        <div class="itemList mb-5 ">
          <v-container class=" pa-1 mb-5 graphicSelector">
            <v-row no-gutters>
              <transition-group
                name="fade"
                tag="div"
                class="col-transition-group"
              >
                <v-col
                  v-for="(trail, i) in trailSelectOptionsFiltered"
                  :key="`trailSelectOptionsImg_${i}`"
                  cols="6"
                  md="6"
                  lg="4"
                  class="pa-0"
                >
                  <v-card class="pa-0 ma-1 mb-2" outlined>
                    <v-img
                      :height="$vuetify.breakpoint.smAndDown ? 120 : 200"
                      :src="
                        trail.featured_image_obj &&
                        trail.featured_image_obj.marker_lg
                          ? trail.featured_image_obj.marker_lg
                          : images[trail.counter % images.length]
                      "
                      class="white--text align-end pr-0 pb-0"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    >
                      <v-card-title
                        class="px-1 pb-1 justify-center heroTitle serif--text"
                        ><center>
                          <engAndTrans :value="trail.Point_Name" />

                          {{
                            trailSelect == trail.slug ? /*"(Selected)"*/ "" : ""
                          }}
                        </center>
                      </v-card-title>
                    </v-img>
                    <v-card-text
                      class="pt-2 pb-0 notranslate mvtitle "
                      translate="no"
                      v-if="
                        trail.formated &&
                          trail.formated.destNamesArray &&
                          trail.formated.stateCodes
                      "
                      style="min-height: 60px;
    text-align: center;
    align-items: center;
"
                    >
                      {{ trail.formated.destNamesArray.join(", ") }}
                      <nobr>
                        ({{
                          trail.formated.stateCodes.join(", ").toUpperCase()
                        }})
                      </nobr>
                    </v-card-text>

                    <v-card-actions
                      class="pa-0 ma-0 d-flex flex-row  justify-space-between"
                    >
                      <v-btn
                        v-show="
                          trail.formated.bookingLink &&
                            !$vuetify.breakpoint.xsOnly
                        "
                        width="49%"
                        @click="openBookingLink(trail)"
                        class="ma-0"
                        elevation="0"
                        dark
                        color="questGold"
                      >
                        Book Now
                      </v-btn>

                      <v-btn
                        :width="
                          trail.formated &&
                          trail.formated.bookingLink &&
                          !$vuetify.breakpoint.xsOnly
                            ? '49%'
                            : '100%'
                        "
                        @click="selectTrail(trail)"
                        class="ma-0"
                        elevation="0"
                        dark
                        color="questGold"
                      >
                        Explore
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </transition-group>
            </v-row>
          </v-container>
          <v-container v-if="false">
            <v-row no-gutters>
              <v-col
                v-for="(trail, i) in trailSelectOptionsFiltered"
                :key="`trailSelectList_${i}`"
                cols="12"
              >
                <div>
                  <a @click="selectTrail(trail)">
                    <b>{{ trail.Point_Name }}</b> ({{
                      trail.formated.stateCodes.join(", ").toUpperCase()
                    }}) Click to Explore
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="false">
            <v-row no-gutters>
              <v-col
                v-for="(trail, i) in trailSelectOptionsFiltered"
                :key="`trailSelectOptions_${i}`"
                cols="12"
                md="6"
              >
                <v-card class="pa-2 ma-1 mb-0" outlined>
                  <v-card-title>{{ trail.Point_Name }}</v-card-title>
                  <v-card-subtitle class="notranslate" translate="no">
                    {{ trail.formated.stateNames.join(", ") }}
                  </v-card-subtitle>

                  <v-card-text class="notranslate" translate="no">
                    {{ trail.formated.destStringArray.join(", ") }}
                  </v-card-text>

                  <v-card-text
                    class="notranslate"
                    translate="no"
                    v-if="
                      trail.formated &&
                        trail.formated.destNamesArray &&
                        trail.formated.stateCodes
                    "
                  >
                    {{ trail.formated.destNamesArray.join(", ") }} ({{
                      trail.formated.stateCodes.join(", ").toUpperCase()
                    }})
                  </v-card-text>

                  <v-card-actions>
                    <v-btn @click="selectTrail(trail)">
                      Select
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "./../../../main";

import engAndTrans from "./../../ui/engAndTrans.vue";

export default {
  name: "TrailSelectorDialog",

  components: {
    engAndTrans
  },
  data() {
    return {
      openTrailSelectorDialog: false,
      shortStates: ["nt", "sa", "wa"],
      selectedStates: [],
      keywordFilter: "",
      images: [
        "./assets/BLANK_ICON.png",
        "./assets/BLANK_ICON.png",
        "./assets/BLANK_ICON.png"
      ]
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      siteObject: state => state.global.siteObject,

      markerList: state => state.markers.markerList,
      trailSelectOptions: state => state.filters.trailSelectOptions,
      trailDestinationSlugs: state => state.filters.trailDestinationSlugs,
      trailSelect: state => state.filters.trailSelect,

      allTaxonomiesLookup: state => state.markers.allTaxonomiesLookup,

      currentCountryNotEnglish: state =>
        state.translator.currentCountryNotEnglish
    }),

    searchStringObj() {
      let searchStringObj = {};
      try {
        let params = new URLSearchParams(window.location.search);
        let entries = params.entries();
        searchStringObj = Object.fromEntries(entries); // {q: 'node', page: '2'}
      } catch (error) {
        //do nothing;
      }
      return searchStringObj;
    },
    validTrail() {
      let rows = this.trailSelectOptions.filter(
        row => row.slug === this?.searchStringObj?.trail
      );

      return rows?.length ? true : false;
    },

    trailSelectOptionsExtras() {
      let output = [];

      let counter = 0;
      this.trailSelectOptions.forEach(oldTrail => {
        let trail = JSON.parse(JSON.stringify(oldTrail));
        counter++;
        trail.counter = counter;
        let formated = {};

        formated.stateNames = trail.postTaxonomy.taxNames.state;

        formated.stateCodes = trail.postTaxonomy.taxSlugs.state;

        let tempLocations = [];

        let destStringArray = [];
        let destNamesArray = [];

        trail.trailDestinationSlug.forEach(dest => {
          let { name, slug } = this.allTaxonomiesLookup.all[
            "markersection"
          ].lookup[dest];

          let stateCode = slug.split("_")[0];

          stateCode = stateCode
            .replace("sat", "sa")
            .replace("wat", "wa")
            .replace("ntt", "nt");

          let stringVal = `${name} (${stateCode.toUpperCase()})`;

          tempLocations.push({ name, slug, stateCode, stringVal });

          destNamesArray.push(name);
          destStringArray.push(stringVal);
        });

        formated.destinations = tempLocations;
        formated.destStringArray = destStringArray;
        formated.destNamesArray = destNamesArray;

        if (trail?.acf?.M_L_QUEST_BOOKING_LINK?.value?.url) {
          formated.bookingLink = trail?.acf?.M_L_QUEST_BOOKING_LINK?.value?.url;
        }

        trail.formated = formated;

        try {
          let keywords = [
            trail.Point_Name,
            ...formated.stateNames,
            ...formated.stateCodes,
            ...formated.destNamesArray
          ];

          keywords = keywords
            .join(" ")
            .split(" ")
            .filter(row => row.length > 2 || this.shortStates.includes(row))
            .join(" ");

          trail.keywords = keywords.toLocaleLowerCase();
        } catch (error) {
          //console.log("keyword error");
        }

        output.push(trail);
      });

      //order by name;
      output = output.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      return output;
    },

    trailSelectOptionsFiltered() {
      let array = this.trailSelectOptionsExtras;

      if (this?.selectedStates?.length) {
        array = array.filter(trail => {
          let itemState = trail?.postTaxonomy?.taxSlugs?.state;

          let found = false;
          itemState.forEach(row => {
            if (this.selectedStates.includes(row)) {
              found = true;
            }
          });

          return found;
        });
      }

      ///keyword bit
      try {
        let words = this.keywordFilter
          .split(" ")
          .map(row => row.trim().toLocaleLowerCase())
          .filter(row => row.length > 2 || this.shortStates.includes(row))
          .map(row => {
            if (this.shortStates.includes(row)) {
              row = ` ${row} `;
            }
            return row;
          });
        //console.log(words);

        if (words.length) {
          //has words, so try to filter items;
          array = array.filter(trail => {
            let { keywords } = trail;
            keywords = ` ${keywords} `;

            let allFound = true;
            words.forEach(word => {
              if (allFound && !keywords.includes(word)) {
                allFound = false;
              }
            });

            return allFound;
          });
        }
      } catch (error) {
        //do nothing;
      }

      return array;
    },

    trailImages() {
      let images = this.trailSelectOptionsFiltered
        .map(trail => trail?.featured_image_obj?.marker_lg)
        .filter(row => row);

      console.log({ images });
      return images;
    }
  },
  methods: {
    preloadImages(imageArray) {
      imageArray.forEach(imageUrl => {
        const img = new Image();
        img.src = imageUrl;
      });
    },
    openBookingLink(trail) {
      if (trail?.formated?.bookingLink?.includes("http")) {
        window.mvAnalyticEvent("gallery", "bookinglink", trail.slug, false);

        window.open(trail?.formated?.bookingLink, "_blank"); // Opens the URL in a new tab/window
      }
    },
    resetFilters() {
      this.keywordFilter = "";
      this.selectedStates = [];
    },
    selectTrail(trail) {
      window.mvAnalyticEvent("gallery", "select", trail.slug, false);

      this.openTrailSelectorDialog = false;
      if (this.trailSelect == trail.slug) {
        eventBus.$emit("nav_map_focus_on_trail");
      } else {
        setTimeout(() => {
          this.$store.dispatch("navUpdateSelectedTrail", trail.slug);
          this.$store.dispatch(
            "filters_controlsUpdate_trailSelect",
            trail.slug
          );
        }, 150);
      }
    }
  },
  created() {
    eventBus.$on("openTrailDialog", () => {
      this.resetFilters();
      this.openTrailSelectorDialog = true;
    });
  },
  mounted() {
    this.preloadImages(this.trailImages);

    let firstVisitToday = false;

    const lastVisit = localStorage.getItem("lastVisitDate");
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    if (lastVisit !== today) {
      this.isFirstVisitToday = true;
      // Run your first-visit event here

      firstVisitToday = true;
      // Update the last visit date
    }

    localStorage.setItem("lastVisitDate", today);

    setTimeout(() => {
      if (!this.validTrail && !this.trailSelect) {
        this.resetFilters();
        this.openTrailSelectorDialog = true;

        setTimeout(() => {
          if (firstVisitToday) {
            eventBus.$emit("loadAocDialog");
          }
        }, 1200);
      }
    }, 3500);
  },

  watch: {
    openTrailSelectorDialog: () => {},

    trailImages: function(newValue) {
      this.preloadImages(newValue);
    },
    currentCountryNotEnglish: function(newValue) {
      //console.log("currentCountryNotEnglish changed", newValue, this);

      if (newValue != true) {
        this.keywordFilter = "";
      }
    }
  }
};
</script>

<style lang="scss">
.trailSelectorDialogWrapper {
  overflow: hidden;
}

.itemList {
  height: calc(600px);
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

/* Transition styles for fading effect */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  //transform: translateY(20px);
}

/* Ensure the transition group does not disrupt layout */
.col-transition-group {
  display: contents;
}

.filterSection .v-text-field {
  width: 170px;
  flex-grow: 0;
  .v-text-field__details {
    display: none;
  }
  .v-input__slot {
    margin: 0;
  }

  .v-text-field__slot input {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .v-input__control {
    flex-grow: unset;
  }
}

.graphicSelector {
  .v-responsive__content,
  .v-responsive__content .v-card__title.heroTitle {
    height: 100%;
  }

  .v-image__image {
    height: 200px;
  }

  .v-card__title.heroTitle {
    line-height: 1.3 !important;
  }

  .v-card__title.heroTitle.serif--text {
    font-size: 1.4rem;
    font-size: 1.6rem;
    padding-left: 10px !important;
    padding-right: 10px !important;

    text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #000000,
      0 0 40px #000000, 0 0 50px #000000, 0 0 60px #0000009c, 0 0 70px #0000009c;
  }
}
.stateSelector {
  .v-btn.v-size--small {
    padding-left: 0;
    padding-right: 0;
    min-width: 40px;
  }
}

.isMobile {
  .trailSelectorToolBar .v-toolbar__title {
    font-size: 1rem;
    line-height: 1.2;
  }

  .stateSelector {
    .v-btn.v-size--small {
      font-size: 0.7rem;
      min-width: 34px;
    }
  }

  .graphicSelector {
    .v-image__image {
      height: 120px;

      .v-card__title {
        background: red;
      }
    }

    .v-card__title {
      font-size: 0.9rem;
      font-weight: 400;
      letter-spacing: 0.0125em;
      line-height: 1rem;
    }

    .v-card__text {
      font-size: 0.675rem;
      font-weight: 400;
      line-height: 1rem;
    }
    .v-btn {
      font-size: 0.7rem !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .v-card__title.heroTitle.serif--text {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.1 !important;
    }
  }

  @media only screen and (max-width: 340px) {
    .v-card__title.heroTitle.serif--text {
      font-size: 1.1rem;

      line-height: 1.1 !important;
    }
  }
}
</style>
