var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.xsOnly
        ? '95%'
        : _vm.$vuetify.breakpoint.mdAndDown
        ? 500
        : 600},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:" "},'span',attrs,false),on),[_vm._v(" Welcome ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"white--text",attrs:{"color":"questGold"}},[_c('v-toolbar',{staticClass:"questGold",attrs:{"dark":"","color":"questGold","elevation":"2","xheight":_vm.$vuetify.breakpoint.smAndDown ? 42 : 54}},[_c('v-spacer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.smAndDown),expression:"!$vuetify.breakpoint.smAndDown"}]}),_c('v-toolbar-title',[_c('font',[_vm._v(" Quest To See Australia ")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('center',{staticClass:"mx-8 title aoc"},[_c('v-card-text',[_c('mvWpContentBlock',{attrs:{"classname":"welcome","posttype":"pages","slug":"welcome"}})],1)],1),(false)?_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":_vm.appSettings.dialogActionButtonColor,"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" FAQ ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":_vm.appSettings.dialogActionButtonColor,"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Quest ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":_vm.appSettings.dialogActionButtonColor,"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" maptivate ")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":_vm.appSettings.dialogActionButtonColor,"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }