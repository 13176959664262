<template>
  <div>
    <div v-if="trailTitle" class="trailTitle mvtitle">
      <engAndTrans :value="trailTitle" />
    </div>

    <div v-else class="ml-3">
      <v-btn
        @click="openTrailSelector()"
        color="questPeach"
        dark
        class="xquestStone--text"
        elevation="0"
      >
        Please Select A Road Trip
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";
import engAndTrans from "./../ui/engAndTrans.vue";

export default {
  name: "mvTrailTitle",

  components: {
    engAndTrans
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      formatTrailLookup: state => state.filters.formatTrailLookup,
      trailSelect: state => state.filters.trailSelect
    }),

    trail() {
      return this.formatTrailLookup[this.trailSelect];
    },
    trailTitle() {
      return this.trail?.title;
    }
  },
  methods: {
    openTrailSelector() {
      eventBus.$emit("openTrailDialog");
    }
  }
};
</script>
<style scopped>
.trailTitle {
  width: 180px;
  max-height: 36px;
  padding: 0 8px; /* Optional: Add some padding */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 18px; /* Half of the height to vertically center a single line */
  vertical-align: middle;
  text-align: left;
  font-weight: 500;
}

.isTabletOrMobile .trailTitle {
  width: 130px;
  max-width: calc(100vw - 623px) !important;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 12px;
  height: auto;
  max-height: 36px;
  font-weight: 400;
  padding: 0 4px;
  font-weight: 500;

  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
}
</style>
