var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:{
    currentCountryNotEnglish: _vm.currentCountryNotEnglish,
    'my-app': true,
    isApple: _vm.isIOS,
    notApple: !_vm.isIOS,

    isComputer: !_vm.$vuetify.breakpoint.mdAndDown,
    isTabletOrComputer: !_vm.$vuetify.breakpoint.smAndDown,
    isSmallComputer: !_vm.$vuetify.breakpoint.smAndDown,
    isSmallPcOrTabletOrMobile: _vm.$vuetify.breakpoint.mdAndDown,
    isTabletOrMobile: _vm.$vuetify.breakpoint.mdAndDown,

    isTablet: _vm.$vuetify.breakpoint.mdOnly,

    isMobile: _vm.$vuetify.breakpoint.smAndDown,
    isXsMobile: _vm.$vuetify.breakpoint.xsOnly,

    trailIsSelect: _vm.trailSelect ? true : false,
    trailBookingLink: _vm.bookingLink ? true : false
  }},[(_vm.ready)?_c('Layout'):_vm._e(),(true)?_c('welcomeDialog',{staticClass:"mr-8",attrs:{"isReady":_vm.ready,"showButton":false}}):_vm._e(),_c('ShareDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }