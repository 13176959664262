<template>
  <div class="mvFocusOnTrailButton">
    <v-btn
      v-show="includedDestinationsArray && includedDestinationsArray.length"
      @click="
        focusOnTrail();
        showMobileList = false;
      "
      title="Focus on Entire Trail"
      dark
      depressed
      notsmall
      class="ml-2 "
      :color="'filterLegendButtonColor'"
    >
      <v-icon>mdi-map-marker-path</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { eventBus } from "../../main";

import { mapState } from "vuex";
export default {
  name: "mvFocusOnTrailButton",

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      //trailSelectOptions: state => state.filters.trailSelectOptions,
      formatTrailLookup: state => state.filters.formatTrailLookup,
      trailSelect: state => state.filters.trailSelect,

      screenQuerySections: state => state.navigation.screenQuerySections,
      sidebarSite: state => state.navigation.sidebarSite,

      markerList: state => state.markers.markerList
    }),

    trail() {
      return this.formatTrailLookup[this.trailSelect];
    },
    includedDestinations() {
      return this.trail?.includedDestinations;
    },

    includedDestinationsArray() {
      try {
        return Object.values(this.trail?.includedDestinations);
      } catch (error) {
        return [];
      }
    },
    sites() {
      return this.markerList.data.features;
    },
    properties() {
      try {
        if (
          this.sites &&
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          //          console.log(this.sites[this.sidebarSite].properties);
          return this.sites[this.sidebarSite].properties;
        } else {
          //return null;
          throw { error: "error" };
        }
      } catch (error) {
        return null;
      }
    },

    currentSectionSlug() {
      return this?.properties?.section_slug;
    },

    activeSection() {
      if (this.currentSectionSlug) {
        return this.currentSectionSlug;
      } else if (this.screenQuerySections.length === 1) {
        return this.screenQuerySections[0];
      } else {
        return null;
      }
    }
  },

  methods: {
    focusOnTrail() {
      eventBus.$emit("nav_map_focus_on_trail");
    }
  }
};
</script>

<style scoped>
.mvFocusOnTrailButton {
  top: 71px;
  position: absolute;
  right: 6px;
}
</style>
