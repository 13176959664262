<template>
  <div class="text-center">
    <v-dialog
      v-model="openIntro"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : 550"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <div
            v-if="$vuetify.breakpoint.xsOnly"
            class="accent--text mobileZooTitle"
          >
            <div
              class="d-flex flex-column align-center mobileZooTitleItemWrapper"
            >
              <div class="align-content-center">
                Acknowledgement
              </div>

              <div class="align-content-center">
                of Country
              </div>
            </div>
          </div>
          <span v-else>Acknowledgement of Country</span>
        </div>
      </template>

      <v-card color="dialogToobarColor" class="white--text">
        <v-toolbar
          class="trailSelectorToolBar"
          dark
          color="dialogToobarColor"
          elevation="2"
          :xheight="$vuetify.breakpoint.smAndDown ? 42 : 54"
        >
          <v-spacer v-show="!$vuetify.breakpoint.smAndDown"></v-spacer>
          <v-toolbar-title>
            <font>
              Acknowledgement of Country
            </font>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn dark icon @click="openIntro = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <center class="mx-8 title aoc">
          <v-card-text
            >Quest acknowledges the Traditional Owners and cultural Custodians
            of the land on which our apartment hotels are located throughout
            Australia and where we gather, work, travel and stay. We recognise
            and celebrate their continuing connection to lands, waterways and
            our local communities. We pay our respects to elders past and
            present for they hold the memories, cultures, traditions and hopes
            of all Aboriginal and Torres Strait Islander peoples.
          </v-card-text>
        </center>

        <v-card-actions v-if="false">
          <v-spacer></v-spacer>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="openIntro = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "./../../../main";

export default {
  name: "AcknowledgementDialogs",
  components: {},
  data() {
    return {
      dontShowAgain: false,
      openIntro: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  methods: {
    /*
    updateDontShowCheckbox() {
      localStorage.setItem("dontShowAcknowedgement", this.dontShowAgain);
    },
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },
*/
  },
  created() {
    eventBus.$on("loadAocDialog", () => {
      this.openIntro = true;
    });
  },
  beforeDestroy() {
    //do something before destroying vue instance
    eventBus.$off("loadAocDialog");
  },

  mounted() {}
};
</script>

<style>
.title.aoc .v-card__text {
  font-size: 1.05rem;
  font-weight: 300;
  line-height: 1.4rem;
}
</style>
