import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

const siteSwitcherService = require("./../service/siteSwitcherService.js");

const themeLight = siteSwitcherService.getSiteSetup().theme;

Vue.use(Vuetify, {
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: "#ff0000",",
        ////accent: "#476B63",
        //// error: "#546B66"
        //"primary--text": "#ff0000"

        primary: "#505149",
        secondary: "#1f3531",
        accent: "#505149", //link text
        error: "#1f3531", //item se
        ////secondary: "#394246lected
        info: "#51524a",
        success: "#061a35",
        //warning: "#00F"
        warning: "#fcfbf1", //marker text

        header: "#a3bba0",
        tabs: "#96a992",

        questGold: "#9b8c5e",
        questStone: "#51524a",
        questCream: "#a8a99e",
        questLightMenu: "#ebe8df",
        questDarkMenu: "#dddbd1",

        questPeach: "#c87250",
        questOrange: "#e5a520",

        transparentBlackClose: "#000000",

        mediaButtonColor: "#9b8c5e",

        filterLegendButtonColor: "#505149",

        dialogActionButtonColor: "black",

        dialogToobarColor: "#51524a",

        logoTextColor: "#505149",

        filterButtonColor: "#e7eaed",

        //mainMenuBarColor: "#dddbd1",
        //menuBarColor: "#ebe8df",

        mainMenuBarColor: "#eeebe1",
        menuBarColor: "#ebe8df",
        footerBarColor: "#dddbd1",
        //        tabSliderColor: "#505149",
        tabSliderColor: "#9b8c5e",

        langButtonColor: "#505149",

        logoColor: "#505149",

        ...themeLight
      }
    }
  }
});
