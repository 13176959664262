//import _ from "lodash";
import { mapState } from "vuex";
const turf = require("@turf/turf");
import { eventBus } from "../../../main";

export default {
  data: () => ({
    trackFitBounds: false,

    minHeight: 400,
    maxHeight: 550
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      //trailSelectOptions: state => state.filters.trailSelectOptions,
      //formatTrailLookup: state => state.filters.formatTrailLookup,
      sidebarSite: state => state.navigation.sidebarSite,
      activeQueryStringMarkerId: state =>
        state.navigation.activeQueryStringMarkerId
    }),

    hasSiteSelected() {
      return (
        this.sidebarSite ||
        this.sidebarSite == 0 ||
        this.activeQueryStringMarkerId
      );
    },

    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    clearTrackFitBounds() {
      setTimeout(() => {
        this.trackFitBounds = false;
      }, 0);
      setTimeout(() => {
        this.trackFitBounds = false;
      }, 10);
    },

    getArrayOfMarkers(props) {
      let actZoomToOnSel = this.appSettings.zoomToOnSelect;

      let zoomLevel =
        this.map.getZoom() < actZoomToOnSel
          ? actZoomToOnSel + 0.5
          : this.map.getZoom();

      let arrayOfPanToMarkers = [];

      try {
        let marker_type = props?.marker_type;
        let section_slug = props?.section_slug;

        try {
          if (
            this?.appSettings?.zoomToOnSelectSectionLookup &&
            marker_type &&
            this?.appSettings?.zoomToOnSelectSectionLookup[marker_type] &&
            !isNaN(
              this?.appSettings?.zoomToOnSelectSectionLookup[marker_type]
            ) &&
            this?.trail?.includedDestinations &&
            this.trail.includedDestinations[section_slug]?.allPoints
          ) {
            arrayOfPanToMarkers = this.trail.includedDestinations[section_slug]
              .allPoints;
          }
        } catch (error) {
          //do nothing;
        }

        if (
          this?.appSettings?.zoomToOnSelectSectionLookup &&
          marker_type &&
          this?.appSettings?.zoomToOnSelectSectionLookup[marker_type] &&
          !isNaN(this?.appSettings?.zoomToOnSelectSectionLookup[marker_type])
        ) {
          zoomLevel = this.appSettings.zoomToOnSelectSectionLookup[marker_type];
        }
      } catch (error) {
        //console.error(error);
      }

      return { arrayOfPanToMarkers, zoomLevel };
    },

    bufferPoints(point, distance, setup) {
      //this add some extra dummy points x meters from site;
      let bearing = 45;

      if (distance && !isNaN(distance)) {
        const pointNorthEast = turf.destination(point, distance, bearing, {
          units: "meters"
        });
        const pointSouthWest = turf.destination(
          point,
          distance,
          bearing + 180,
          { units: "meters" }
        );

        setup.bounds.extend([...pointNorthEast.geometry.coordinates]);
        setup.bounds.extend([...pointSouthWest.geometry.coordinates]);

        return { pointNorthEast, pointSouthWest };
      }
    },
    flyToArrayOfMarkers(arrayOfPanToMarkers, zoomLevel) {
      var firstPoint = turf.point([
        arrayOfPanToMarkers[0].Longitude,
        arrayOfPanToMarkers[0].Latitude
      ]);

      const setup = { bounds: new window.mapboxgl.LngLatBounds() };

      this.bufferPoints(
        firstPoint,
        this?.appSettings?.flyToDestBufferDestinationInM,
        setup
      );

      // Loop through each location and extend bounds to include each point

      arrayOfPanToMarkers.forEach(location => {
        let usePoint = true;

        if (this?.appSettings?.maxDistanceOfPointFromDestination) {
          try {
            var toPoint = turf.point([location.Longitude, location.Latitude]);
            let distance = turf.distance(firstPoint, toPoint);
            if (
              this?.appSettings?.maxDistanceOfPointFromDestination < distance
            ) {
              usePoint = false;
            }
          } catch (e) {
            //do nothing;
          }
        }
        if (usePoint) {
          setup.bounds.extend([location.Longitude, location.Latitude]);
          var newPoint = turf.point([location.Longitude, location.Latitude]);
          this.bufferPoints(
            newPoint,
            this?.appSettings?.flyToDestBufferOtherPointInM,
            setup
          );
        }
      });
      // Fit the map to the bounds, with optional padding

      this.trackFitBounds = true;
      this.map.fitBounds(setup.bounds, {
        padding: 25, // Padding in pixels
        maxZoom: zoomLevel
      });
    },
    updatePaddingZoomForRemotePopup(coordinates, flyTo, props, where) {
      //split this up into 3 functions, zoom to pin, zoom to area, and a wrapper function;
      if ((props && props?.hide) || (!coordinates[0] && !coordinates[1])) {
        //do nothing!!
      } else {
        if (where) {
          //do nothing
        }

        if (window.outerHeight < 700 || this.$vuetify.breakpoint.mobile) {
          //setting padding so info window canload up ;
          //this.map.setPadding({ top: (window.outerHeight - 80) / 2 });
        } else {
          //this.map.setPadding({ top: 0 });
        }

        let { arrayOfPanToMarkers, zoomLevel } = this.getArrayOfMarkers(props);

        if (arrayOfPanToMarkers?.length > 1) {
          this.flyToArrayOfMarkers(arrayOfPanToMarkers, zoomLevel);
        } else if (flyTo) {
          let options = {
            //padding: 10, // Padding in pixels
            offset: [0, this.getOffset(props)],
            speed: this.useCluster ? 0.5 : 1,
            center: coordinates,
            zoom: zoomLevel
          };

          this.map.flyTo(options);
        }
      }
    },

    mvGetScreenHightObj() {
      let height = this.$refs.mapWrapper.clientHeight;
      let headerBottom;

      let footerTop;
      try {
        //this is needed because of mobile phones,
        const header = document.querySelector(".mainMvToolBar");
        const footer = document.querySelector(".mainMvFooter");

        headerBottom = header.getBoundingClientRect().bottom;

        footerTop = footer.getBoundingClientRect().top;

        if (header && footer) {
          height = footerTop - headerBottom;
        } else {
          throw { error: "error" };
        }
      } catch (error) {
        //console.log("Error, mvGetScreenHightObj");
      }

      return { height, headerBottom, footerTop };
    },
    getOffset(props) {
      let infoWinHeight = props?.media?.length
        ? this.maxHeight
        : this.minHeight;

      let offsetY = 0;

      try {
        let mapHeight = this.mvGetScreenHightObj().height;

        if (mapHeight / 2 < infoWinHeight) {
          offsetY = infoWinHeight / 2;
        }

        if (mapHeight / 2 < offsetY + 40) {
          offsetY = mapHeight / 2;
          offsetY = offsetY - 80;
        }
      } catch (e) {
        //do nothing;
      }

      return offsetY;
    },

    moveEndCheckPopupPositionGen() {
      try {
        const popupContent = document.querySelector(".mapboxgl-popup-content ");

        if (popupContent) {
          //const menubarHeight = 64 + 80;

          let popupTopDistanceToTop = popupContent.getBoundingClientRect().top; //80 for extra menu;

          let popupBottomDistanceToTop = popupContent.getBoundingClientRect()
            .bottom;

          let {
            height: mapHeight,
            headerBottom,
            footerTop
          } = this.mvGetScreenHightObj();

          let distFromTop = popupTopDistanceToTop - headerBottom - 60;
          //let distFromTopToBottom = popupBottomDistanceToTop - headerBottom - 60;
          let distFromBottom = footerTop - popupBottomDistanceToTop;

          let absoluteValueOfTop = -1;
          try {
            absoluteValueOfTop = Math.abs(distFromTop);
          } catch (error) {
            //do nothing;
            //console.log("error");
          }

          let absoluteValueOfBottom = -1;
          try {
            absoluteValueOfBottom = Math.abs(distFromBottom);
          } catch (error) {
            //do nothing;
            //console.log("error");
          }
          let offset = false;

          let message = "";

          if (
            absoluteValueOfTop > mapHeight * 2 ||
            absoluteValueOfBottom > mapHeight * 2
          ) {
            //console.log("Too far out, dont do anything, something has gone wrong");

            message = "Too far out";
          } else if (distFromTop < 0) {
            //console.log("Above Top, Move down!!", {distFromTop,distFromBottom});

            message = `Above Top, Move down`;

            offset = distFromTop * -1 + 10;
          } else if (distFromBottom < 0) {
            //console.log("Below bottom, Move up!!", distFromBottom);

            offset = distFromBottom - 20; //-20 so not sitting on bottom!!;

            //console.log({ offset, headerBottom, popupTopDistanceToTop });

            if (popupTopDistanceToTop + offset < headerBottom + 40) {
              let offsetOffeset =
                headerBottom - (popupTopDistanceToTop + offset);

              offset = offset + offsetOffeset + 60;
            }

            //if((popupTopDistanceToTop+offset))

            message = `Below bottom, Move up!!`;
          } else {
            //console.log("is good!!", offset);

            message = `is good!!!`;
          }

          let output = JSON.stringify({
            o: Math.round(offset),
            t: Math.round(distFromTop),
            b: Math.round(distFromBottom),
            h: Math.round(mapHeight),
            pt: Math.round(popupTopDistanceToTop),
            pb: Math.round(popupBottomDistanceToTop),

            hb: Math.round(headerBottom),
            ft: Math.round(footerTop),
            message
          });

          if (output) {
            //alert(output);
          }
          if (!isNaN(offset) && offset !== false) {
            if (this.isIOS) {
              //do nothing;
            }

            //else {
            const coordinates = this.map.getCenter();

            if (offset > 0) {
              //offset = offset + 40;
            } else {
              //offset = offset - 40;
            }

            let options = {
              offset: [0, offset],
              center: coordinates
            };

            this.map.flyTo(options);
            //}
          }
        }
      } catch (e) {
        //console.log(e);
        //do nothing;
      }
    },
    moveEndCheckPopupPosition() {
      //this.moveEndCheckPopupPositionGen();
      if (this.trackFitBounds == true && this.hasSiteSelected) {
        this.trackFitBounds = false;

        try {
          //console.log("move end fired, trackFitBounds");

          const popupContent = document.querySelector(
            ".mapboxgl-popup-content"
          );

          if (popupContent) {
            const menubarHeight = 64 + 80;

            let popupTopDistanceToTop = popupContent.getBoundingClientRect()
              .top;
            //let popupTopDistanceToTopRaw = popupContent.getBoundingClientRect().top;

            //let popupBottomDistanceToTop = popupContent.getBoundingClientRect().bottom;
            popupTopDistanceToTop = popupTopDistanceToTop - menubarHeight;

            //console.log(`Distance from top of window: ${popupTopDistanceToTop}px`);
            let {
              height: mapHeight
              //headerBottom,
              //footerTop
            } = this.mvGetScreenHightObj();

            //console.log({ headerBottom, popupTopDistanceToTopRaw });
            //console.log({ footerTop, popupBottomDistanceToTop });

            let absoluteValueOfDistance = -1;
            try {
              absoluteValueOfDistance = Math.abs(popupTopDistanceToTop);
            } catch (error) {
              //do nothing;
              //console.log("error");
            }

            //console.log({ absoluteValueOfDistance, mapHeight });

            //need to ensure this disn't isn't too far off;
            if (
              absoluteValueOfDistance &&
              mapHeight &&
              absoluteValueOfDistance < mapHeight * 2
            ) {
              if (popupTopDistanceToTop < 0) {
                const coordinates = this.map.getCenter();

                let options = {
                  offset: [0, popupTopDistanceToTop * -1],
                  center: coordinates
                };

                this.map.flyTo(options);
              }
            }
          }
        } catch (e) {
          //console.log(e);
          //do nothing;
        }
      }

      this.trackFitBounds = false;
    },

    //worker functions

    goToPointByPointName(pointName) {
      let [point] = this.sites.filter(row => {
        return row.properties.Point_Name == pointName;
      });

      if (point && point.properties) {
        this.loadMarkerByIdFromMenu(point.properties.index);
      }
    },

    flyToPoint(layer, site) {
      this.updatePaddingZoomForRemotePopup(
        site?.geometry?.coordinates,
        true,
        site?.properties,
        "D"
      );

      if (layer == "markerList") {
        this.createPopupFromIndex(site?.properties?.index);
      }
    }
  },
  created() {
    eventBus.$on("flyTo_clearTrackFitBounds", () => {
      this.clearTrackFitBounds();
    });

    eventBus.$on("flyToPoint", (layer, site) => {
      this.flyToPoint(layer, site);
    });

    eventBus.$on("nav_map_flyToLongLat", coordinates => {
      this.clearAllPopups();

      this.updatePaddingZoomForRemotePopup(coordinates, true, {}, "B");
      if (this.$vuetify.breakpoint.mdAndDown) {
        eventBus.$emit("nav_layout_closeMenu");
      }
    });
    eventBus.$on("nav_map_flyToFeature", feature => {
      this.clearAllPopupsBasic();

      let properties = feature?.properties;
      let coordinates = feature?.geometry?.coordinates;

      if (coordinates) {
        this.updatePaddingZoomForRemotePopup(
          coordinates,
          true,
          properties,
          "C"
        );
      }

      if (this.$vuetify.breakpoint.smAndDown) {
        eventBus.$emit("nav_layout_closeMenu");
      }

      let openToolTip = () => {
        let event = {
          features: [feature]
        };

        this.createTooltip(event);
      };

      setTimeout(() => {
        openToolTip();
      }, 1000);
    });

    eventBus.$on("nav_map_flyToOpenPopUpByName", pointName => {
      this.goToPointByPointName(pointName);
    });

    eventBus.$on("nav_map_flyToOpenPopUp", index => {
      this.createPopupFromIndex(index);
      if (this.$vuetify.breakpoint.mdAndDown) {
        eventBus.$emit("nav_layout_closeMenu");
      }
    });
  },
  destroyed() {},
  mounted() {},

  watch: {}
};
