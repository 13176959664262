<template>
  <div v-show="bookingLink" class="floatingBookingButton">
    <v-btn
      elevation="0"
      style="height: 22px; min-width:116px"
      color="questStone"
      class=" pa-0 ma-0 px-1 white--text"
      @click="openUrl()"
    >
      <font> Book Now</font>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "mvBookingButtons",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      formatTrailLookup: state => state.filters.formatTrailLookup,
      trailSelect: state => state.filters.trailSelect
    }),

    trail() {
      return this.formatTrailLookup[this.trailSelect];
    },
    trailTitle() {
      return this.trail?.title;
    },
    bookingLink() {
      return this.trail?.acf?.M_L_QUEST_BOOKING_LINK?.value?.url;
    }
  },
  methods: {
    openUrl() {
      if (this.bookingLink?.includes("http")) {
        window.open(this.bookingLink, "_blank"); // Opens the URL in a new tab/window
      }
    }
  }
};
</script>
<style>
.floatingBookingButton {
  position: absolute;
  bottom: 46px;
  left: 9px;
}

.isMobile .floatingBookingButton {
  position: absolute;
  bottom: 40px;
  left: 9px;
}
</style>
