<template>
  <div class="ml-2">
    <div v-if="trailTitle" class="trailTitleMobile mvtitle">
      <engAndTrans :value="trailTitle" />
    </div>

    <div v-else>
      <v-btn
        @click="openTrailSelector()"
        color="questPeach"
        dark
        elevation="0"
        class="px-0 mobileSelectARoadtripBtn"
      >
        <div class="mobileSelectARoadtrip">
          Please Select A Road Trip
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";
import engAndTrans from "./../ui/engAndTrans.vue";

export default {
  name: "mvTrailTitle",

  components: {
    engAndTrans
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      formatTrailLookup: state => state.filters.formatTrailLookup,
      trailSelect: state => state.filters.trailSelect
    }),

    trail() {
      return this.formatTrailLookup[this.trailSelect];
    },
    trailTitle() {
      return this.trail?.title;
    }
  },
  methods: {
    openTrailSelector() {
      eventBus.$emit("openTrailDialog");
    }
  }
};
</script>
<style scopped>
.trailTitleMobile {
  width: 170px;
  max-width: calc(100vw - 240px);

  height: auto;
  max-height: 41px;
  padding: 0 8px;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 13px;
  vertical-align: middle;
  text-align: left;
  font-size: 11px;
}

.mobileSelectARoadtrip {
  width: 110px;

  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 11px;
  white-space: normal;
  padding: 0 4px;
}

@media only screen and (max-width: 340px) {
  .trailTitleMobile {
    max-width: calc(100vw - 224px);
  }

  button.v-btn.mobileSelectARoadtripBtn {
    max-width: calc(100vw - 231px) !important;
    min-width: 0 !important;
    width: 100% !important;
    padding: 0 !important;
  }

  .mobileSelectARoadtrip {
    max-width: calc(100vw - 231px) !important;

    display: -webkit-box;
    line-height: 1;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 9px;
    height: 28px;
    overflow: hidden;
    font-weight: 400;
    padding: 0 4px;
  }
}
</style>
