//import _ from "lodash";
import { mapState } from "vuex";

import mapTrailService from "./../services/mapTrailService.js";

export default {
  data: () => ({
    //layerState: false
    trailLayerIdName: "questToSeeAusTrailGeom"
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      //trailSelectOptions: state => state.filters.trailSelectOptions,
      formatTrailLookup: state => state.filters.formatTrailLookup,
      trailSelect: state => state.filters.trailSelect,

      navIsLoading: state => state.navigation.navIsLoading,
      navFirstLoadTargetSite: state => state.navigation.navFirstLoadTargetSite
    }),

    trail() {
      return this.formatTrailLookup[this.trailSelect];
    },
    includedDestinations() {
      return this.trail?.includedDestinations;
    },
    includedDestinationsArray() {
      try {
        return Object.values(this.trail?.includedDestinations);
      } catch (error) {
        return [];
      }
    }
  },

  methods: {
    updateTrailData(selectedTrailSlug) {
      try {
        let thisSourceData = this.map.getSource(this.trailLayerIdName)._data;

        thisSourceData?.features?.forEach(feature => {
          let { properties } = feature;
          let { slug } = properties;

          if (slug && selectedTrailSlug && slug === selectedTrailSlug) {
            feature.properties.active = true;
          } else {
            try {
              feature.properties.active = false;
            } catch (error) {
              //do nothing
            }
          }
        });

        this.map.getSource(this.trailLayerIdName).setData(thisSourceData);
      } catch (error) {
        //console.error(error);
      }
    },
    async initMapTrailLineGeom(map) {
      let trailGeoJson = await mapTrailService.getTrailGeom(
        this.formatTrailLookup
      );
      map.addSource(this.trailLayerIdName, {
        type: "geojson",
        data: trailGeoJson
      });
      map.addLayer(
        {
          id: this.trailLayerIdName,
          type: "line",
          source: this.trailLayerIdName,
          paint: {
            // Use a conditional expression to set the color based on 'active'
            "line-color": [
              "case",
              ["==", ["get", "active"], true],
              "#9b8c5e", // Red if active is true
              "#808080" // Grey if active is false or undefined
            ],

            "line-opacity": [
              "case",
              ["==", ["get", "active"], true],
              1, // Red if active is true
              0.2 // Grey if active is false or undefined
            ],

            "line-width": [
              "step",
              ["zoom"],
              2, //default

              4,
              3,
              8,
              5,
              12,
              7
            ],
            "line-dasharray": [
              "step",
              ["zoom"],
              [1], //default
              4,
              [4, 1],
              8,
              [4, 2],
              12,
              [4, 4]
            ]
          }
        },
        "admin-0-boundary-disputed"
      );

      // Assuming you have already added the line layer to the map
      map.on("click", this.trailLayerIdName, e => {
        // The event object contains information about the click and features
        const features = e.features; // Features under the click
        if (features.length > 0) {
          const clickedFeature = features[0]; // Get the first feature under the click

          let lineSlug = clickedFeature?.properties?.slug;

          if (!this.trailSelect || this.trailSelect) {
            this.$store.dispatch("navUpdateSelectedTrail", lineSlug);
            this.$store.dispatch(
              "filters_controlsUpdate_trailSelect",
              lineSlug
            );
          }

          // You can access properties of the feature like this
        }
      });

      // Optionally, change the mouse cursor to a pointer when hovering over the line layer
      map.on("mouseenter", this.trailLayerIdName, () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", this.trailLayerIdName, () => {
        map.getCanvas().style.cursor = "";
        this.clearTooltip();
      });

      map.on("mousemove", this.trailLayerIdName, e => {
        try {
          this.createTooltipTrail(e);
        } catch (error) {
          //do nothing
        }
      });
    },

    focusMapOnDestinations() {
      window.mvAnalyticEvent("map", "foucsOnTrail");

      try {
        let properties, coordinates;
        if (this.navIsLoading && this.navFirstLoadTargetSite) {
          try {
            properties = this?.navFirstLoadTargetSite?.properties;
            coordinates = this?.navFirstLoadTargetSite?.geometry?.coordinates;
            coordinates.reverse();
          } catch (error) {
            //do nothing;
          }
        }

        if (this.navIsLoading && properties && coordinates) {
          //focus on a marker or destinations;
          this.updatePaddingZoomForRemotePopup(
            coordinates,
            true,
            properties,
            "trail-mixin"
          );
          setTimeout(() => {
            this.createPopup(coordinates, properties, false);
            this.$store.dispatch("navUpdateSidebarSite", properties.index);
          }, 1000);
        } else if (this.includedDestinationsArray.length) {
          const bounds = new window.mapboxgl.LngLatBounds();
          let locations = [];
          const useAllPoints = true;

          if (useAllPoints === true) {
            this.includedDestinationsArray.forEach(row => {
              row.allPoints.forEach(point => {
                locations.push(point);
              });
            });
          } else {
            locations = this.includedDestinationsArray.map(row => row.point);
          }

          // Loop through each location and extend bounds to include each point
          locations.forEach(location => {
            if (location?.marker_type == "destination") {
              bounds.extend([location.Longitude, location.Latitude]);
            } else {
              //bounds.extend([location.Longitude, location.Latitude]);
            }
          });
          // Fit the map to the bounds, with optional padding

          let options = {};
          options.padding = this.$vuetify.breakpoint.smAndDown
            ? this.appSettings.boundFitDefaultMapPaddingMobile
            : this.appSettings.boundFitDefaultMapPadding;

          try {
            options.padding.right = this.appSettings.paddingRightForTrailPadding;
          } catch (error) {
            //do nothing;
          }
          this.map.fitBounds(bounds, options);
        }
      } catch (e) {
        console.error("error", { e });
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {},

  watch: {
    trailSelect(newValue) {
      //this.trailSelect();
      this.updateTrailData(newValue);
    },
    includedDestinationsArray() {
      this.focusMapOnDestinations();
    }
  }
};
