<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="
        $vuetify.breakpoint.xsOnly
          ? '95%'
          : $vuetify.breakpoint.mdAndDown
          ? 500
          : 600
      "
    >
      <template v-slot:activator="{ on, attrs }" v-show="showButton">
        <span v-bind="attrs" v-on="on" class=" " v-show="showButton">
          Welcome
        </span>
      </template>

      <v-card color="questGold" class="white--text">
        <v-toolbar
          class="questGold"
          dark
          color="questGold"
          elevation="2"
          :xheight="$vuetify.breakpoint.smAndDown ? 42 : 54"
        >
          <v-spacer v-show="!$vuetify.breakpoint.smAndDown"></v-spacer>
          <v-toolbar-title>
            <font>
              Quest To See Australia
            </font>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn dark icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <center class="mx-8 title aoc">
          <v-card-text>
            <mvWpContentBlock
              classname="welcome"
              posttype="pages"
              slug="welcome"
            ></mvWpContentBlock>
          </v-card-text>
        </center>

        <v-card-actions v-if="false">
          <v-btn
            class="white--text"
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            FAQ
          </v-btn>

          <v-btn
            class="white--text"
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Quest
          </v-btn>

          <v-btn
            class="white--text"
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            maptivate
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../../main";
import { mapState } from "vuex";

import mvWpContentBlock from "../../ui/mvWpContent/mvWpContentBlock.vue";

export default {
  name: "aboutDialog",
  components: {
    mvWpContentBlock
  },
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Welcome to Melbourne Zoo Maptivate"
    };
  },
  computed: {
    ...mapState({
      siteId: state => state.global.siteId,
      siteObject: state => state.global.siteObject,
      appSettings: state => state.global.appSettings,
      imageLog: state => state.markers.imageLog
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", () => {
      this.dialog = true;
    });
  },
  props: {
    isReady: Boolean,
    showButton: Boolean
  },
  methods: {},
  mounted() {
    let bootOption = false;

    if (bootOption != true) {
      this.dialog = false;
    }
  }
};
</script>

<style>
.imageWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper,
  .introVideo {
    max-height: calc(100vh - 240px);
  }

  .imageWrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }

  .isApple .contentWrapper,
  .isApple .introVideo {
    max-height: calc(100vh - 260px);
  }

  .isApple .imageWrapper {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }
}

.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
