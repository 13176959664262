const sites = [
  {
    index: 0,
    key: "quest",
    title: "Quest To See Australia",
    titleShort: "Quest",
    short: "Melbourne",

    xtitleShortLine1: "Quest",
    xtitleShortLine2: "To See Australia",
    maptivateUrl: "https://questtoseeaustralia.maptivateapp.com.au",
    url: "https://www.questapartments.com.au",
    mapurl: "https://www.questapartments.com.au",
    cmsUrl: "https://cms01.maptivateapp.com.au/questtoseeaustralia",
    editPost:
      "https://cms01.maptivateapp.com.au/questtoseeaustralia/wp-admin/post.php?post=",
    siteSetup: require("../service/sites/quest/siteSetupQuest.js"),

    subsiteNumber: "11",
    subsiteId: "questtoseeaustralia",
    shareTitle: "Quest Maptivate",
    shareDescription:
      "Find travel inspiration in our local-approved itineraries, and let Quest look after you – wherever your journey takes you."
  }
];

const defaultSiteKey = sites[0].key;
const siteLookup = sites.reduce(function(map, obj) {
  map[obj.key] = obj;
  return map;
}, {});

const getId = () => {
  try {
    let id = window.siteMode;

    if (
      id &&
      typeof id === "string" &&
      id.length &&
      id.length < 10 &&
      siteLookup &&
      siteLookup[id]
    ) {
      return id;
    } else {
      throw { error: true };
    }
  } catch (error) {
    return defaultSiteKey;
  }
};

const currentSiteId = getId();
const currentSiteObj = siteLookup[currentSiteId];

module.exports.getId = () => {
  return currentSiteId;
};
module.exports.getSites = () => {
  return JSON.parse(JSON.stringify(sites));
};
module.exports.getSiteObj = () => {
  let setup = { ...currentSiteObj };

  delete setup.siteSetup;

  return setup;
};

module.exports.getSiteSetup = () => {
  let setup = { ...currentSiteObj.siteSetup };

  try {
    setup.global.appSettings.wpEditSite = currentSiteObj.editPost;
  } catch (error) {
    //do nothing;
  }

  return setup;
};
